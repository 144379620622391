.heading {
  line-height: var(--lineHeightTitle);
  color: var(--colorTextTitle);

  &[data-level='0'] {
    letter-spacing: -0.05em;
    font-size: var(--fontSizeH0);
  }

  &[data-level='1'] {
    letter-spacing: -0.05em;
    font-size: var(--fontSizeH1);
  }

  &[data-level='2'] {
    font-size: var(--fontSizeH2);
    letter-spacing: -0.04em;
  }

  &[data-level='3'] {
    font-size: var(--fontSizeH3);
    letter-spacing: -0.02em;
  }

  &[data-level='4'] {
    font-size: var(--fontSizeH4);
    letter-spacing: -0.01em;
  }

  &[data-level='5'] {
    font-size: var(--fontSizeH5);
  }

  &[data-align='auto'] {
    text-align: inherit;
  }

  &[data-align='start'] {
    text-align: start;
  }

  &[data-align='center'] {
    text-align: center;
  }

  &[data-weight='regular'] {
    font-weight: var(--fontWeightRegular);
  }

  &[data-weight='medium'] {
    font-weight: var(--fontWeightMedium);
  }

  &[data-weight='bold'] {
    font-weight: var(--fontWeightBold);
  }
}
