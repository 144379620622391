.link {
  --lineStrokeWidth: 2px;
  --linkColor: var(--rgbPrimary);
  --lineOpacity: 0.3;
  --filledLineGradient: linear-gradient(rgb(var(--linkColor)), rgb(var(--linkColor)));
  --unfilledLineGradient: linear-gradient(
    rgb(var(--linkColor) / var(--lineOpacity)),
    rgb(var(--linkColor) / var(--lineOpacity))
  );

  cursor: pointer;
  display: inline;
  color: rgb(var(--linkColor));
  background: var(--filledLineGradient) no-repeat 100% 100% / 0 var(--lineStrokeWidth),
    var(--unfilledLineGradient) no-repeat 0 100% / 100% var(--lineStrokeWidth);
  padding-bottom: var(--lineStrokeWidth);

  &:hover,
  &:focus {
    background: var(--filledLineGradient) no-repeat 0 100% / 100% var(--lineStrokeWidth),
      var(--unfilledLineGradient) no-repeat 0 100% / 100% var(--lineStrokeWidth);
  }

  @media (--mediaUseMotion) {
    transition-duration: var(--durationM);
    transition-timing-function: var(--bezierFastoutSlowin);
    transition-property: background-size;
  }

  &[data-secondary='true'] {
    --linkColor: var(--rgbText);
  }
}
